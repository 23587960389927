import { Routes } from '@angular/router';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';

export const routes: Routes = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
    data: { title: 'Auth' },
  },
  {
    path: 'soft-delete',
    loadChildren: () =>
      import('./features/soft-delete/soft-delete.module').then((m) => m.SoftDeleteModule),
    data: { title: 'Question' },
  },
  {
    path: 'single-link-batch',
    loadChildren: () =>
      import('./features/single-link-batch/single-link-batch.module').then((m) => m.SingleLinkBatchModule),
    data: { title: 'Single Batch Link' },
  },
  {
    path: 'question',
    loadChildren: () =>
      import('./features/question/question.module').then((m) => m.QuestionModule),
    data: { title: 'Question' },
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./features/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
    data: { title: 'Subscription' },
  },
  {
    path: 'admin-setting',
    loadChildren: () =>
      import('./features/admin-settings/admin-settings.module').then((m)=> m.AdminSettingsModule),
    data: { title: 'Admin Setting' },
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];
