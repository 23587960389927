import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { apiInterceptor } from './core/services/api.interceptor';
import { provideToastr } from 'ngx-toastr';
import { errorInterceptor } from './core/services/error.interceptor';
// import { tokenInterceptor } from './core/services/token.interceptor';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { getQuestionsReducer, selectQuestionsFeatureKey } from './store/user.reducer';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
  provideAnimations(),
  provideHttpClient(withInterceptors([apiInterceptor, errorInterceptor, 
    // tokenInterceptor
  ])),
  provideToastr(), // Toastr providers
  provideStore(),
  provideStoreDevtools({
    maxAge: 25, // Retains last 25 states
    logOnly: !isDevMode(), // Restrict extension to log-only mode
    autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
    traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
  }),
  provideState({name: selectQuestionsFeatureKey, reducer: getQuestionsReducer}),
]
};
