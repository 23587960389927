@if(loggedIn){
<header class="header-top">
  <nav class="navbar navbar-expand-xl">
    <div class="container pl-2 pr-5">
      <a class="navbar-brand logo" routerLink="/pages/student-home">
        <img class="img-ml" src="/assets/images/logo2.png" alt="Prep-study" />
      </a>
      <span class="title">Prep Study - Admin Dashboard</span>
      <button class="navbar-toggler">
        <i class="fas fa-bars"></i>
      </button>

      <div class="collapse navbar-collapse" style="justify-content: flex-end">
        <ul class="navbar-nav textnav">
          <li class="nav-item dropdown">
            <span class="f-10">{{version}}</span>
            <a mat-button [matMenuTriggerFor]="menu" class="menu">
              <img src="/assets/images/profile.png" alt="Prep-study" />
              <span><i class="fas fa-chevron-down"></i></span>
            </a>
            <mat-menu #menu="matMenu">
              <!-- <button mat-menu-item routerLink="/pages/student-profile" title="My Profile" class="menu-item">
                <a>My Profile</a>
              </button>
              <button mat-menu-item class="menu-item" routerLink="/pages/change-password">
                <a>Change Password</a>
              </button> -->
              <button mat-menu-item title="Logout" class="menu-item" (click)="logout()">
                <a>Logout</a>
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
}
<main>
  @if(!loggedIn){
    <router-outlet></router-outlet>
  }

  @if(loggedIn){
  <div [ngClass]="{'sidebar-open': !isSidebarCollapsed, 'sidebar-closed': isSidebarCollapsed}" class="sidebar">
    <!-- for collapsable side bar -->
    <!-- <button type="button" (click)="toggleSidebar()" class="btn btn-link btn-menu">
      <i [ngClass]="isSidebarCollapsed ? 'la la-bars icon' : 'la la-angle-left icon'" [ngClass]="{'rotate': !isCollapsed}"></i>
    </button> -->
    <ul class="list-unstyled ps-0">
      <!-- <li class="mb-1">
        <span class="btn btn-toggle align-items-center rounded collapsed" (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseSingleLinkBatches"
        [ngClass]="{'rotate': !isCollapsed}" [ngClass]="{'rotate': !isCollapsed}">
      </span>
      <span class="cursor-pointer" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseSingleLinkBatches">Tag Mapping</span>
        <div class="ul-ml" id="collapseBasic" [collapse]="isCollapsed">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li><a class="link-dark rounded" routerLink="/question/question-list"
                (click)="setActiveLink($event)">Question Tag Mapping</a></li>
            </ul>
        </div>
      </li> -->
      <li class="mb-1 ml-main">
        <span class="btn btn-toggle align-items-center rounded collapsed" (click)="isCollapsed1 = !isCollapsed1"
          [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseSingleLinkBatches"
          [ngClass]="{'rotate': !isCollapsed1}" [ngClass]="{'rotate': !isCollapsed1}">
        </span>
        <span class="cursor-pointer" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1"
          aria-controls="collapseSingleLinkBatches">Single Link Batches</span>
        <div class="ul-ml" id="collapseSingleLinkBatches" [collapse]="isCollapsed1">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <!-- <li><a class="link-dark rounded" routerLink="/single-link-batch/add" (click)="setActiveLink($event)">Single
                Link Batches - Create</a></li> -->
            <li><a class="link-dark rounded" routerLink="/single-link-batch/list" (click)="setActiveLink($event)">Single
                Link Batches - List</a></li>
          </ul>
        </div>
      </li>
      <li class="mb-1 ml-main">
        <span class="btn btn-toggle align-items-center rounded collapsed" (click)="isCollapsed2 = !isCollapsed2"
          [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseSingleLinkBatches"
          [ngClass]="{'rotate': !isCollapsed2}" [ngClass]="{'rotate': !isCollapsed2}">
        </span>
        <span class="cursor-pointer" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2"
          aria-controls="collapseSingleLinkBatches">Questions Management</span>
        <div class="ul-ml" id="collapseSingleLinkBatches" [collapse]="isCollapsed2">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <!-- <li><a class="link-dark rounded" routerLink="/question/create-update" (click)="setActiveLink($event)">Questions - Create</a></li> -->
            <li><a class="link-dark rounded" routerLink="/question/list" (click)="setActiveLink($event)">Questions - List</a></li>
          </ul>
        </div>
      </li>
      <li class="mb-1">
        <span class="btn btn-toggle align-items-center rounded collapsed" (click)="isCollapsed3 = !isCollapsed3"
        [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseSingleLinkBatches"
        [ngClass]="{'rotate': !isCollapsed3}" [ngClass]="{'rotate': !isCollapsed3}">
      </span>
      <span class="cursor-pointer" (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="!isCollapsed3"
      aria-controls="collapseSingleLinkBatches">Soft Delete</span>
        <div class="ul-ml" id="collapseBasic" [collapse]="isCollapsed3">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <!-- <li><a class="link-dark rounded" routerLink="/soft-delete/exam-type" (click)="setActiveLink($event)">Exam
                Type</a></li>
            <li><a class="link-dark rounded" routerLink="/soft-delete/grade" (click)="setActiveLink($event)">Grade</a></li>
            <li><a class="link-dark rounded" routerLink="/soft-delete/subject" (click)="setActiveLink($event)">Subject</a>
            </li>
            <li><a class="link-dark rounded" routerLink="/soft-delete/topic" (click)="setActiveLink($event)">Topic</a></li> -->
            <li><a class="link-dark rounded" routerLink="/soft-delete/question-list" (click)="setActiveLink($event)">Questions</a></li>
          </ul>
        </div>
      </li>
      <li class="mb-1">
        <span class="btn btn-toggle align-items-center rounded collapsed" (click)="isCollapsed4 = !isCollapsed4"
          [attr.aria-expanded]="!isCollapsed4" aria-controls="collapseSingleLinkBatches"
          [ngClass]="{'rotate': !isCollapsed4}" [ngClass]="{'rotate': !isCollapsed4}">
        </span>
        <span class="cursor-pointer" (click)="isCollapsed4 = !isCollapsed4" [attr.aria-expanded]="!isCollapsed4"
          aria-controls="collapseSingleLinkBatches">Subscriptions</span>
        <div class="ul-ml" id="collapseSingleLinkBatches" [collapse]="isCollapsed4">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li><a class="link-dark rounded" routerLink="/subscription/list" (click)="setActiveLink($event)">Subscription Plans - List</a></li>
            <li><a class="link-dark rounded" routerLink="/subscription/history-list" (click)="setActiveLink($event)">Subscriber - History</a></li>
          </ul>
        </div>
      </li>
      <li class="mb-1">
        <span class="btn btn-toggle align-items-center rounded collapsed" (click)="isCollapsed5 = !isCollapsed5"
          [attr.aria-expanded]="!isCollapsed5" aria-controls="collapseSingleLinkBatches"
          [ngClass]="{'rotate': !isCollapsed5}" [ngClass]="{'rotate': !isCollapsed5}">
        </span>
        <span class="cursor-pointer" (click)="isCollapsed5 = !isCollapsed5" [attr.aria-expanded]="!isCollapsed5"
          aria-controls="collapseSingleLinkBatches">Admin Settings</span>
        <div class="ul-ml" id="collapseSingleLinkBatches" [collapse]="isCollapsed5">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li><a class="link-dark rounded" routerLink="/admin-setting/list" (click)="setActiveLink($event)">Admin Settings - List</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
  }
</main>

<!-- to display the loader -->
<div id="loader" *ngIf="loading">
  <div class="loader-wrap">
    <div class="loader"></div>
    <div class="loader-text">loading</div>
  </div>
</div>